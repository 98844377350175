// TODO: Move to /shared/constants.js
export const SW_UPDATE_QUERY_PARAM = "__update__"
export const AUTH_QUERY_PARAM = "__auth__"
export const PREVIEW_QUERY_PARAM = "__preview__"
export const HIDE_QUERY_PARAM = "__hide__"
export const LIGHT_BOX_KEY = "mklbItem"
export const DEPTH_OF_THREAD = 1

export const HIDDEN_ELEMENTS = {
  TABBAR: "tabbar",
  TOOLBAR: "toolbar",
  PRODUCT: "product",
  COUPON: "coupon",
  AMOUNT: "amount",
  ORDER_SUMMARY: "order-summary"
}

export const MANAGED_PAGE_PREFIX = "/me/"

export const ACCOUNT_APP_PRIMARY_DOMAINS = ["fitterapp.app", "mvt.so"]

// Messages
export const MESSAGE_TYPES = {
  MESSAGE: "message",
  IMAGE: "image",
  VIDEO: "video",
  ATTACHMENT: "attachment",
  GROUPED: "grouped",
  MESSAGE_REACTION: "message-reaction",
  MESSAGE_DELETED: "message-deleted",
  MESSAGE_UPDATED: "message-updated",
  NOTIFICATION: "notification",
  REACTION: "reaction",
  MEMBER_BLOCKED: "member-blocked"
}

export const MESSAGE_REACTION_TYPES = {
  LIKE: "like",
  UNLIKE: "unlike"
}

export const MESSAGE_SEND_STATUS = {
  SENDING: "sending",
  ERROR: "error"
}

// Push Notifications
export const PUSH_NOTIFICATION_STAGES = {
  REQUEST: "#notifications",
  GRANTED: "#notifications/granted",
  DENIED: "#notifications/denied"
}
