import isBuilderContext from "@/lib/is-platform/is-builder-context"

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_isempty
export const isEmpty = <T>(
  obj: T | null | undefined
): obj is null | undefined =>
  obj === null ||
  obj === undefined ||
  obj === "" ||
  (typeof obj === "object" &&
    !(obj instanceof Date) &&
    obj !== null &&
    !Object.entries(obj).length)

export const isPresent = <T>(obj: T | null | undefined): obj is T =>
  !isEmpty(obj)

export const isBoolean = (bool: any): bool is boolean =>
  typeof bool === "boolean"

//https://stackoverflow.com/a/33121880
export const unique = <T>(array: T[]): T[] => [...new Set(array)]

export const getImageUrl = (
  image: any,
  defaultUrl: string | null = null
): string | null =>
  !isEmpty(image) && !isEmpty(image.image) ? image.image.url : defaultUrl

export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1)

// https://youmightnotneed.com/lodash/#omit
export const omit = (
  object: { [key: string]: any },
  keysToRemove: string[]
): { [key: string]: any } => {
  const obj = { ...object }
  keysToRemove.forEach(key => delete obj[key])
  return obj
}

export const isObject = (obj: any): obj is object =>
  typeof obj === "object" && obj !== null

export const clientWidth = (): number =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth

export const clientHeight = (): number =>
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight

export const getHostName = (url: string): string => {
  const parser = document.createElement("a")
  parser.href = url

  if (parser.hostname === "localhost") {
    return url
  } else {
    return parser.hostname
  }
}

export const isString = (str: any): str is string => typeof str === "string"

export const pluralize = (string: string, amount = 2): string =>
  amount > 1 && !string.endsWith("s") ? `${string}s` : string

export const getInitials = (string: string): string => {
  if (!isString(string)) return ""

  const SIMPLE_EMAIL_REGEX = /^\S+@\S+\.\S+$/

  if (string.match(SIMPLE_EMAIL_REGEX)) {
    return string.slice(0, 2).toUpperCase()
  } else {
    return string
      .replace(/[^a-zA-Z- ]/g, " ")
      .replace(/  +/g, " ")
      .split(" ")
      .slice(0, 2)
      .map(s => s.charAt(0))
      .join("")
      .toUpperCase()
  }
}

export const hasKey = (obj: any, key: string): boolean =>
  Object.prototype.hasOwnProperty.call(obj, key)

export const isWebLink = (link: string): boolean =>
  !!link &&
  (link.startsWith("http") ||
    link.startsWith("mailto") ||
    link.startsWith("tel"))

export const replaceQueryParam = (router: any, param: any): void =>
  router.replace({ query: { ...router.currentRoute.query, ...param } })

export const eventPath = (event: Event): EventTarget[] =>
  event.composedPath && event.composedPath()

export const findAsync = async <T>(
  arr: T[],
  asyncCallback: (item: any) => Promise<boolean>
): Promise<T | undefined> => {
  const promises = arr.map(asyncCallback)
  const results = await Promise.all(promises)
  const index = results.findIndex(result => result)
  return arr[index]
}

export const formatSlug = (slug: string): string =>
  slug
    .split("-")
    .map(capitalizeFirstLetter)
    .join(" ")

export const getParentElementsBySelector = (
  node: Element,
  selector: string,
  includeSelf = false,
  acc: Element[] = []
): Element[] => {
  if (includeSelf && node.matches(selector)) {
    acc.push(node)
  }

  if (node.parentElement) {
    return getParentElementsBySelector(node.parentElement, selector, true, acc)
  }

  return acc
}

export const getSafeAreaInsetTop = (): number => {
  const safeAreaInsetTop = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--safe-area-inset-top")

  return parseInt(safeAreaInsetTop, 10) || 0
}

export { isBuilderContext }
